<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("VEHICLES.VEHICLES_LIST") }}</h3>
          </div>

          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openVehicleCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_VEHICLES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("VEHICLES.ADD_VEHICLE") }}
              </span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_VEHICLES)"
              :objectType="'vehicles'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <vehicle-list-table
        @onAddVehicle="openVehicleCreateModal"
        @onViewVehicle="openVehicleViewModal"
        @onEditVehicle="openVehicleEditModal"
        @onDeleteVehicle="deleteVehicle"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'VEHICLE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeVehicleModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("VEHICLES.VIEW_VEHICLE") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openVehicle"
                :objectType="'vehicles'"
                :objectId="openVehicle.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <!-- <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <button class="edit" @click="openVehicleEditModal(openVehicle)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>

                <button
                  v-if="
                    openVehicle.status?.toLowerCase() ===
                      VEHICLE_STATUS_COMPLETED.toLowerCase() &&
                    openVehicle.is_validated !== true
                  "
                  class="edit"
                  @click="openVehicleValidateModal(openVehicle)"
                >
                  <i class="fal fa-check"></i>
                  <span>{{ $t("COMMON.VALIDATE") }}</span>
                </button>

                <button
                  v-if="
                    $currentUserCan($permissions.PERM_INVALIDATE_VEHICLES) &&
                    openVehicle.status?.toLowerCase() ===
                      VEHICLE_STATUS_COMPLETED.toLowerCase() &&
                    openVehicle.is_validated === true
                  "
                  class="edit"
                  @click="invalidateVehicle(openVehicle)"
                >
                  <i class="fal fa-redo"></i>
                  <span>{{ $t("COMMON.INVALIDATE") }}</span>
                </button>

                <button
                  v-if="
                    $currentUserCan($permissions.PERM_CLOSE_VEHICLE_AUCTION) &&
                    openVehicle.status?.toLowerCase() ===
                      VEHICLE_STATUS_COMPLETED.toLowerCase() &&
                    openVehicle.is_validated === true &&
                    openVehicle.auction_closed == 0 &&
                    openVehicle.auction_status?.toLowerCase() ===
                      VEHICLE_AUCTION_STATUS_ONGOING.toLowerCase()
                  "
                  class="edit"
                  @click="closeVehicleAuction(openVehicle)"
                >
                  <i class="ni ni-fat-remove icon-shape-danger"></i>
                  <span>{{ $t("VEHICLES.CLOSE_AUCTION") }}</span>
                </button>

                <button class="edit" @click="deleteVehicle(openVehicle)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown> -->

              <button class="expand" id="expand-button">
                <img
                  src="../../../../../public/img/e-arrows-expand.svg"
                  alt="icon"
                />
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-vehicle-page
              v-if="openVehicle"
              :vehicleId="openVehicle.id"
              @onEditVehicle="openVehicleEditModal"
              @onDeleteVehicle="deleteVehicle"
              @onValidateVehicle="openVehicleValidateModal"
              @onInvalidateVehicle="invalidateVehicle"
              @onCloseVehicleAuction="closeVehicleAuction"
              @onCancelVehicleSale="cancelVehicleSale"
              @onConfirmVehicleSale="confirmVehicleSale"
              @onCancelTransaction="cancelTransaction"
              @onReauctionVehicle="reauctionVehicle"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'VEHICLE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeVehicleModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("VEHICLES.EDIT_VEHICLE") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <button class="view" @click="openVehicleViewModal(openVehicle)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>

              <button class="expand" id="expand-button">
                <img
                  src="../../../../../public/img/e-arrows-expand.svg"
                  alt="icon"
                />
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <edit-vehicle-page
              v-if="openVehicle"
              :vehicleId="openVehicle.id"
              @onViewVehicle="openVehicleViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'VEHICLE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeVehicleModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("VEHICLES.ADD_VEHICLE") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <button class="expand" id="expand-button">
                <img
                  src="../../../../../public/img/e-arrows-expand.svg"
                  alt="icon"
                />
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <add-vehicle-page
              @onViewVehicle="openVehicleViewModal"
              @onEditVehicle="openVehicleEditModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isValidateVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isValidateVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'VEHICLE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeVehicleModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("VEHICLES.VALIDATE_VEHICLE") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <button class="view" @click="openVehicleViewModal(openVehicle)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>

              <button class="expand" id="expand-button">
                <img
                  src="../../../../../public/img/e-arrows-expand.svg"
                  alt="icon"
                />
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <validate-vehicle-page
              v-if="openVehicle"
              :vehicleId="openVehicle.id"
              @onViewVehicle="openVehicleViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import VehicleListTable from "./partials/VehicleListTable.vue";
import EditVehiclePage from "./components/EditVehicleComponent.vue";
import AddVehiclePage from "./components/AddVehicleComponent.vue";
import ViewVehiclePage from "./components/ViewVehicleComponent.vue";
import ValidateVehiclePage from "./components/ValidateVehicleInfoComponent.vue";
import {
  VEHICLE_STATUS_COMPLETED,
  VEHICLE_AUCTION_STATUS_ONGOING,
  QUERY_ACTIONS_VALIDATE,
} from "@/constants/vehicles";

export default {
  layout: "DashboardLayout",

  components: {
    VehicleListTable,
    NotificationSubscription,
    EditVehiclePage,
    AddVehiclePage,
    ViewVehiclePage,
    ValidateVehiclePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const vehicleId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewVehicleModalOpened = false;
    let isEditVehicleModalOpened = false;
    let isAddVehicleModalOpened = false;
    let isValidateVehicleModalOpened = false;
    let openVehicle = null;
    if (vehicleId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewVehicleModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditVehicleModalOpened = true;
      } else if (action === QUERY_ACTIONS_VALIDATE) {
        isValidateVehicleModalOpened = true;
      }
      openVehicle = { id: vehicleId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddVehicleModalOpened = true;
    }
    return {
      isViewVehicleModalOpened: isViewVehicleModalOpened,
      isEditVehicleModalOpened: isEditVehicleModalOpened,
      isAddVehicleModalOpened: isAddVehicleModalOpened,
      isValidateVehicleModalOpened: isValidateVehicleModalOpened,
      openVehicle: openVehicle,
      renderKey: 1,
      VEHICLE_STATUS_COMPLETED,
      VEHICLE_AUCTION_STATUS_ONGOING,
    };
  },

  methods: {
    openVehicleCreateModal() {
      this.closeVehicleModal();
      this.isAddVehicleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Vehicles",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openVehicleViewModal(vehicle, reRender = false) {
      this.closeVehicleModal();
      this.openVehicle = vehicle;
      this.isViewVehicleModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Vehicles",
          query: { id: this.openVehicle.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openVehicleEditModal(vehicle) {
      this.closeVehicleModal();
      this.openVehicle = vehicle;
      this.isEditVehicleModalOpened = true;

      const nextTab =
        this.openVehicle.current_step === undefined ||
        this.openVehicle.status?.toLowerCase() ===
          VEHICLE_STATUS_COMPLETED.toLowerCase()
          ? 0
          : this.openVehicle.current_step < 3
          ? (this.openVehicle.current_step ?? 0) + 1
          : 3;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Vehicles",
          query: {
            id: this.openVehicle.id,
            action: QUERY_ACTIONS_EDIT,
            tab: nextTab,
          },
        }).href
      );
    },
    closeVehicleModal() {
      this.isAddVehicleModalOpened = false;
      this.isViewVehicleModalOpened = false;
      this.isEditVehicleModalOpened = false;
      this.isValidateVehicleModalOpened = false;
      this.openVehicle = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Vehicles",
          query: {},
        }).href
      );
    },

    async openVehicleValidateModal(vehicle) {
      // const confirmation = await swal.fire({
      //   text: this.$t("VEHICLES.VALIDATE_THIS_VEHICLE"),
      //   type: "question",
      //   customClass: {
      //     popup: "delete-popup",
      //   },
      //   buttonsStyling: false,
      //   buttonsStyling: false,
      //   showCancelButton: true,
      //   confirmButtonText: this.$t("COMMON.YES"),
      //   cancelButtonText: this.$t("COMMON.NO"),
      //   confirmButtonClass: "btn base-button btn-success",
      //   cancelButtonClass: "btn base-button",
      // });

      // try {
      //   if (confirmation.value === true) {
      //     await this.$store.dispatch("vehicles/validate", {
      //       id: vehicle.id,
      //       event_id: vehicle.event?.id ?? null,
      //       auction_end_date: vehicle.auction_end_date ?? null,
      //       sale_confirmation_date: vehicle.sale_confirmation_date ?? null,
      //     });

      //     this.renderKey++;

      //     this.closeVehicleModal();

      //     this.$notify({
      //       type: "success",
      //       message: this.$t("VEHICLES.VEHICLE_VALIDATED"),
      //     });
      //   }
      // } catch (error) {
      //   // await this.$showRequestError(error, this.$t);

      //   this.$notify({
      //     type: "danger",
      //     message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
      //   });
      // }

      this.closeVehicleModal();
      this.openVehicle = vehicle;
      this.isValidateVehicleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Vehicles",
          query: { id: this.openVehicle.id, action: QUERY_ACTIONS_VALIDATE },
        }).href
      );
    },

    async closeVehicleAuction(vehicle) {
      const confirmation = await swal.fire({
        text: this.$t("VEHICLES.CLOSE_AUCTION_THIS_VEHICLE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-warning",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("vehicles/closeAuction", vehicle.id);

          this.renderKey++;

          this.closeVehicleModal();

          this.$notify({
            type: "success",
            message: this.$t("VEHICLES.VEHICLE_AUCTION_CLOSED"),
          });
        }
      } catch (error) {
        // await this.$showRequestError(error, this.$t);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async invalidateVehicle(vehicle) {
      const confirmation = await swal.fire({
        text: this.$t("VEHICLES.INVALIDATE_THIS_VEHICLE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-warning",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("vehicles/invalidate", vehicle.id);

          this.renderKey++;

          this.closeVehicleModal();

          this.$notify({
            type: "success",
            message: this.$t("VEHICLES.VEHICLE_INVALIDATED"),
          });
        }
      } catch (error) {
        // await this.$showRequestError(error, this.$t);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteVehicle(vehicle) {
      const confirmation = await swal.fire({
        text: this.$t("VEHICLES.DELETE_THIS_VEHICLE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn base-button btn-primary",
        cancelButtonClass: "btn base-button btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("vehicles/destroy", vehicle.id);
          this.renderKey++;
          this.closeVehicleModal();
          this.$notify({
            type: "success",
            message: this.$t("VEHICLES.VEHICLE_DELETED"),
          });
        }
      } catch (error) {
        // await this.$showRequestError(error);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelVehicleSale(vehicle) {
      const confirmation = await swal.fire({
        text: this.$t("VEHICLES.CANCEL_SALE_THIS_VEHICLE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-warning",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("vehicles/cancelSale", vehicle.id);

          this.renderKey++;

          this.closeVehicleModal();

          this.$notify({
            type: "success",
            message: this.$t("VEHICLES.VEHICLE_SALE_CANCELED"),
          });
        }
      } catch (error) {
        // await this.$showRequestError(error, this.$t);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async confirmVehicleSale(vehicle) {
      const confirmation = await swal.fire({
        text: this.$t("VEHICLES.CONFIRM_SALE_THIS_VEHICLE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-warning",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("vehicles/confirmSale", vehicle.id);

          this.renderKey++;

          this.closeVehicleModal();

          this.$notify({
            type: "success",
            message: this.$t("VEHICLES.VEHICLE_SALE_CONFIRMED"),
          });
        }
      } catch (error) {
        // await this.$showRequestError(error, this.$t);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelTransaction(transaction) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("TRANSACTIONS.CANCEL_TRANSACTION_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("transactions/cancel", transaction.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("TRANSACTIONS.TRANSACTION_CANCELED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async reauctionVehicle(vehicle) {
      const confirmation = await swal.fire({
        text: this.$t("VEHICLES.RE_AUCTION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-warning",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          const newVehicle = await this.$store.dispatch(
            "vehicles/reauction",
            vehicle.id
          );

          console.log("new vehicle", newVehicle.id);

          this.renderKey++;

          this.closeVehicleModal();

          this.$notify({
            type: "success",
            message: this.$t("VEHICLES.VEHICLE_RE_AUCTIONNED"),
          });

          this.openVehicleEditModal({ ...newVehicle, current_step: 3 });
        }
      } catch (error) {
        // await this.$showRequestError(error, this.$t);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
